import React, { useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { withWidth } from "@material-ui/core"
import Img from "gatsby-image"
import "./index.scss"
import ReactHtmlParser from "react-html-parser"

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: "none",
    border: "1px solid rgba(1,2,4,0.05)",
    cursor: "pointer",
  },
  media: {
    height: 376,
  },
  cardpic: {
    height: 200,
  },
  image: {
    height: 500,
    width: "100%",
  },
  cardimage: {
    maxHeight: 400,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  mt60: {
    marginTop: 60,
  },
}))

const AboutCard = ({ width, aboutTeamCards }) => {
  const classes = useStyles()
  const isSmall = width === "xs" || width === "sm"
  const flipCardRef = useRef([])
  const flipCardInnerRef = useRef([])

  const flipCard = index => {
    if (flipCardInnerRef.current[index].style.transform === "rotateY(180deg)") {
      flipCardInnerRef.current[index].style.transform = "rotateY(0deg)"
    } else {
      flipCardInnerRef.current[index].style.transform = "rotateY(180deg)"
    }
  }
  return (
    <Grid
      container
      spacing={3}
      style={
        isSmall
          ? { justifyContent: "center" }
          : { justifyContent: "flex-start" }
      }
    >
      {aboutTeamCards?.length > 0 &&
        aboutTeamCards.map((member, index) => (
          <Grid item lg={3} xs={12} md={6} key={index}>
            <Card className={classes.card}>
              <div
                className="flip-card"
                ref={flipCardRef[index]}
                onClick={() =>
                  (!!member.wechatQrcode || !!member.wechatQrcodeText) &&
                  flipCard(index)
                }
              >
                <div
                  className="flip-card-inner"
                  ref={el => (flipCardInnerRef.current[index] = el)}
                >
                  <div className="flip-card-front">
                    <Img
                      className={classes.cardpic}
                      fluid={member?.memberImage?.fluid}
                      alt={member?.memberImage?.title}
                    />
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={[classes.subtitle, classes.uppercase]}
                        style={{
                          fontFamily: "MabryProBold",
                          fontSize: "20px",
                        }}
                      >
                        {member.memberName}
                      </Typography>
                      <Typography
                        gutterBottom
                        className={`${classes.title} title`}
                        style={{
                          fontFamily: "MabryProLight",
                          fontSize: "12px",
                          textTransform: "uppercase",
                        }}
                      >
                        {ReactHtmlParser(
                          member?.position?.childMarkdownRemark?.html
                        )}
                      </Typography>

                      <Typography
                        className={classes.subtitle}
                        style={{
                          fontFamily: "MabryProLight",
                          fontSize: "16px",
                          height: 119,
                          overflow: "hidden",
                          display: "-webkit-box",
                          webkitLineClamp: "5",
                          webkitBoxOrient: "vertical",
                        }}
                      >
                        {member.selfDescription.selfDescription}
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {!!member?.wechatQrcodeText && (
                          <Button
                            style={{
                              marginTop: 30,
                              height: 36,
                              border: "1px solid rgba(1,2,4,0.1)",
                              padding: "0 15px",
                            }}
                            className={classes.button}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {!!member?.wechatIcon?.fluid && (
                                <img
                                  alt=""
                                  style={{
                                    width: 25,
                                    height: 25,
                                    marginRight: 5,
                                  }}
                                  src={member.wechatIcon.fluid.src}
                                />
                              )}

                              <Typography
                                style={{
                                  fontFamily: "MabryProRegular",
                                  fontSize: "16px",
                                  textTransform: "lowercase",
                                }}
                              >
                                {member.wechatUsername}
                              </Typography>
                            </div>
                          </Button>
                        )}
                        {!!member?.wechatQrcode?.fluid && (
                          <img
                            alt=""
                            style={{
                              marginTop: "20px",
                              height: "50px",
                              width: "50px",
                            }}
                            src={member.wechatQrcode.fluid.src}
                          />
                        )}
                      </div>
                    </CardContent>
                  </div>
                  <div className="flip-card-back">
                    <p>{member?.wechatQrcodeText}</p>
                    {!!member?.wechatQrcode?.fluid && (
                      <Img
                        fluid={member.wechatQrcode.fluid}
                        style={{ height: "300px", width: "300px" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        ))}
    </Grid>
  )
}
export default withWidth()(AboutCard)
