import React from "react"
import { Typography, Grid, Container, withWidth } from "@material-ui/core/"


const Contact = ({ title, officesData, width }) => {
  const isSmall = width === "xs" || width === "sm"

  return (
    <Container
      style={ isSmall ? { marginLeft: 0, marginRight: 0 } : { marginLeft: 100, marginRight: 100 }}
    >
      <Typography variant="h2">{title}</Typography>
      <Grid
        container
        style={{ marginTop: 30 }}
        justifyContent={
          isSmall ? "center" : "space-between"
        }
        spacing={3}
      >
        {officesData?.length > 0 &&
          officesData.map((office, index) => (
            <Grid item lg={3} xs={12} md={6} key={index}>
              <Typography
                style={{
                  fontFamily: "MabryProBold",
                  fontSize: "16px",
                  lineHeight: "26px",
                  height: "26px",
                }}
              >
                {office.officeName}
              </Typography>
              <Typography
                style={{
                  width: "70%",
                  fontFamily: "MabryProLight",
                  fontSize: "16px",
                  marginTop: 8,
                }}
              >
                {office.officeAddress} <br /> {office.officePostalCode}
              </Typography>
              <Typography
                style={{
                  fontFamily: "MabryProRegular",
                  fontSize: "16px",

                  marginTop: 20,
                }}
              >
                {office.officeEmail}
              </Typography>
              <Typography
                style={{
                  fontFamily: "MabryProRegular",
                  fontSize: "16px",
                  marginTop: 8,
                }}
              >
                {office.officeNumber}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Container>
  )
}
export default withWidth()(Contact)
