import React from "react"
import { graphql } from "gatsby"
import { Container, Typography, Grid, Divider, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import AboutCard from "../components/AboutCard"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Textfield from "../components/TextField"
import VideoPlayer from "../components/video"
import { SEO } from "../components/SEO"

export const query = graphql`
  query($locale: String!) {
    allContentfulAboutPageModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          seoTitle
          seoDescription
          bannerImage {
            fluid {
              ...GatsbyContentfulFluid
            }
            title
          }
          video {
            file {
              url
            }
          }
          bannerSubTitle
          bannerDescription {
            childMarkdownRemark {
              html
            }
          }
          aboutTeamCards {
            memberName
            memberImage {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
            selfDescription {
              selfDescription
            }
            position {
              childMarkdownRemark {
                html
              }
            }
            wechatUsername
            wechatQrcodeText
            wechatQrcode {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
            wechatIcon {
              fluid {
                ...GatsbyContentfulFluid
              }
              title
            }
          }
          officeSectionTitle
          officesReferences {
            officeName
            officeEmail
            officeNumber
            officeAddress
            officePostalCode
          }
          queriesSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
}))

const ContactScreen = ({
  pageContext,
  location,
  data: { allContentfulAboutPageModel },
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const classes = useStyles()
  const {
    seoTitle,
    seoDescription,
    bannerImage,
    video,
    bannerSubTitle,
    bannerDescription,
    aboutTeamCards,
    officeSectionTitle,
    officesReferences,
    queriesSectionImage,
  } = allContentfulAboutPageModel?.edges?.[0]?.node

  return (
    <Layout selected={"about"} location={location} pageContext={pageContext}>
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <Container className={locale === "zh" && "chinese-version"}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <div className="video-section">
              <VideoPlayer
                video={video?.file?.url}
                poster={bannerImage?.fluid?.src}
              />
            </div>
          </Grid>
          <Hidden smDowm>
            <Grid item md={1} />
          </Hidden>
          <Grid item xs={12} md={4} alignItems="center">
            <Typography variant="h1">{bannerSubTitle}</Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: bannerDescription?.childMarkdownRemark?.html,
              }}
            />
          </Grid>
        </Grid>
        <section className={classes.sectionWrapper}>
          <AboutCard aboutTeamCards={aboutTeamCards} />
        </section>
        <Divider />
        <section className={classes.sectionWrapper}>
          <Contact title={officeSectionTitle} officesData={officesReferences} />
        </section>
        <Divider />
      </Container>
      <Textfield
        customImage={queriesSectionImage}
        locale={pageContext.locale}
      />
      <Container>
        <Divider />
      </Container>
      <Footer />
    </Layout>
  )
}

export default ContactScreen
